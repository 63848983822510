import React from 'react'

import Layout from '../../components/Layout'
import PedicureChairNavBar from '../../components/PedicureChairNavBar'

const metaData = [
  {
    name: 'description',
    content: 'Pedicure Spa Chair',
  },
  {
    name: 'keywords',
    content:
      'perdicure chair, nail, nail furniture, ask us, nail salon furniture, nail chair',
  },
  { name: 'author', content: 'Contego Spa Design' },
]

const IndexPage = () => (
  <Layout
    title="Pedicure Spa Chairs | Contego Spa Designs | Pedicure Spa Chair"
    metaData={metaData}
  >
    <PedicureChairNavBar />
  </Layout>
)

export default IndexPage
