import React from 'react'
import classNames from 'classnames'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import headerStyle from '../styles/navbar.module.scss'

const PedicureChairNavBar = () => (
  <>
    <div className="container-fluid px-2 my-4">
      <h1 className="text-center pt-4">Our Products</h1>
      <ul
        className={classNames(
          headerStyle.navBar,
          'd-flex flex-wrap  align-items-center justify-content-center'
        )}
      >
        <li className={classNames(headerStyle.navBarItem, 'mb-4')}>
          <AniLink
            paintDrip
            duration={1.62}
            hex="#ffffff"
            to="/products/pedicure-spa-chairs"
            activeClassName={headerStyle.activeNavBarItem}
            className={headerStyle.navBarLink}
          >
            Pedicure Spa Chairs
          </AniLink>
        </li>
        <li className={classNames(headerStyle.navBarItem, 'mb-4')}>
          <AniLink
            paintDrip
            duration={1.62}
            hex="#ffffff"
            to="/products/accessories"
            activeClassName={headerStyle.activeNavBarItem}
            className={headerStyle.navBarLink}
          >
            Accessories
          </AniLink>
        </li>
      </ul>
    </div>
  </>
)

export default PedicureChairNavBar
